.height-width-56px {
  height: 56px;
  width: 56px;
}

.voice-recorder-control-button-size {
  width: 48px;
  height: 48px;
}

.opacity-60-percent {
  opacity: 0.6;
}

.visibility-hidden{
  visibility: hidden;
}
