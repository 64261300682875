.height-width-56px {
  height: 56px;
  width: 56px;
}

.parent-width_-68px {
  width: calc(100% - 68px);
}

.rotate-90 {
  transform: rotate(90deg);
}
.rotate-270 {
  transform: rotate(270deg);
}

.remaining-body-height_-229px {
  height: calc(100% - 166px);
}
