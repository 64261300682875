* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*:not(input, textarea) {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

html {
  height: 100%;
}

body {
  height: 100%;
  font-family: redHatDisplay-regular;
  color: var(--secondaryColor);
}

.horizontal-ruler-line-grey {
  border-bottom: 2px solid #f1f1f1;
}

input {
  outline: none;
}

/* Variable declarations */
:root {
  --spacing: 8px;
  --primaryColor: #2965dd;
  --secondaryColor: #404040;
  --tertiaryColor: #c2c2c2;
  --quaternaryColor: #f2f2f2;
}

.user-select-none {
  user-select: none;
}

.cursor-pointer {
  cursor: pointer;
}

.pointer-events-none {
  pointer-events: none;
}

/* font family global classes*/

.font-family-gilroy-light {
  font-family: gilroy-light;
}

.font-family-RHD-medium {
  font-weight: normal;
  font-family: redHatDisplay-medium;
}

.font-family-RHD-regular {
  font-weight: normal;
  font-family: redHatDisplay-regular;
}

/* font size global classes */
.letter-spacing-large {
  letter-spacing: 0.4px;
}

.font-size-smaller {
  font-size: 10px;
}

.font-size-smaller.letter-spacing-large {
  letter-spacing: 0.4px;
}

.font-size-small {
  font-size: 12px;
}

.font-size-medium {
  font-size: 14px;
}

.font-size-default {
  font-size: 16px;
}

.font-size-large {
  font-size: 18px;
}

.font-size-larger {
  font-size: 20px;
}

/*  font color global classes  */
.font-color-white {
  color: white;
}

.font-color-primary {
  color: var(--primaryColor);
}

.font-color-secondary {
  color: var(--secondaryColor);
}

.font-color-tertiary {
  color: var(--tertiaryColor);
}

.font-color-black {
  color: black;
}

.font-color-red {
  color: #e64539;
}

.font-color-grey {
  color: #f1f1ff;
}

.font-weight-300 {
  font-weight: 300;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-normal {
  font-weight: normal;
}

.text-overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.letter-spacing-4-percentage {
  letter-spacing: 0.6px;
}

/*  visibility  global classes  */
.visibility-visible {
  visibility: visible;
}

.visibility-hidden {
  visibility: hidden;
}

.background-color-lite-grey {
  background-color: #e5e5e5;
}

.background-white {
  background: white;
}

.background-linear-gradient-default {
  background: linear-gradient(139.67deg, #00a000 24.46%, #14f27a 100%);
}

.background-transparent {
  background: transparent;
}

.background-color-tertiary {
  background: var(--tertiaryColor);
}

.background-color-primary {
  background-color: var(--primaryColor);
}

.background-color-quaternary {
  background-color: var(--quaternaryColor);
}

.background-color-red {
  background: #e64539;
}

.background-color-grey {
  background: #f1f1f1;
}

.background-color-secondary {
  background-color: #f5f5f5;
}

.background-color-black {
  background-color: #000;
}

.background-color-transparent {
  background-color: transparent;
}

/*  border-radius  global classes  */
.border-radius-default {
  border-radius: 8px;
}

.border-radius-0 {
  border-radius: 0px;
}

.border-bottom-radius-default {
  border-radius: 0 0 8px 8px;
}

.border-top-radius-default {
  border-radius: 8px 8px 0 0;
}

.border-radius-100-percentage {
  border-radius: 100%;
}

.border-radius-50-percentage {
  border-radius: 50%;
}

.border-radius-25-percentage {
  border-radius: 25%;
}

.border-red {
  border: 1px solid #e64539;
}

.border-primaryColor {
  border: 1px solid var(--primaryColor);
}

.border-tertiaryColor {
  border: 1px solid var(--tertiaryColor);
}

.border-secondary {
  border: 1px solid var(--secondaryColor);
}

.border-bottom-1px {
  border-bottom: 1px solid #f1f1f1;
}

.border-1px-e5e5e5 {
  border: 1px solid #e5e5e5;
}

.box-shadow-default {
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 5px rgba(0, 0, 0, 0.12),
    0px 2px 2px rgba(0, 0, 0, 0.15);
}

.box-shadow-none {
  box-shadow: none;
}

/* list style*/
.list-style-none {
  list-style: none;
}

/* global padding classes*/
.padding-smaller {
  padding: calc(var(--spacing) * 0.25);
}

.padding-small {
  padding: calc(var(--spacing) * 0.5);
}

.padding-default {
  padding: calc(var(--spacing) * 1);
}

.padding-medium {
  padding: calc(var(--spacing) * 1.5);
}

.padding-large {
  padding: calc(var(--spacing) * 2);
}

.padding-larger {
  padding: calc(var(--spacing) * 3);
}

/*global padding top spacing classes*/
.padding-top-smaller {
  padding-top: calc(var(--spacing) * 0.25);
}

.padding-top-small {
  padding-top: calc(var(--spacing) * 0.5);
}

.padding-top-default {
  padding-top: calc(var(--spacing) * 1);
}

.padding-top-medium {
  padding-top: calc(var(--spacing) * 1.5);
}

.padding-top-large {
  padding-top: calc(var(--spacing) * 2);
}

.padding-top-larger {
  padding-top: calc(var(--spacing) * 3);
}

/*global padding bottom spacing classes*/
.padding-bottom-smaller {
  padding-bottom: calc(var(--spacing) * 0.25);
}

.padding-bottom-small {
  padding-bottom: calc(var(--spacing) * 0.5);
}

.padding-bottom-default {
  padding-bottom: calc(var(--spacing) * 1);
}

.padding-bottom-medium {
  padding-bottom: calc(var(--spacing) * 1.5);
}

.padding-bottom-large {
  padding-bottom: calc(var(--spacing) * 2);
}

.padding-bottom-larger {
  padding-bottom: calc(var(--spacing) * 3);
}

/*global padding right spacing classes*/
.padding-right-smaller {
  padding-right: calc(var(--spacing) * 0.25);
}

.padding-right-small {
  padding-right: calc(var(--spacing) * 0.5);
}

.padding-right-default {
  padding-right: calc(var(--spacing) * 1);
}

.padding-right-medium {
  padding-right: calc(var(--spacing) * 1.5);
}

.padding-right-large {
  padding-right: calc(var(--spacing) * 2);
}

.padding-right-larger {
  padding-right: calc(var(--spacing) * 3);
}

/*-- global padding left spacing classes --*/
.padding-left-smaller {
  padding-left: calc(var(--spacing) * 0.25);
}

.padding-left-small {
  padding-left: calc(var(--spacing) * 0.5);
}

.padding-left-default {
  padding-left: calc(var(--spacing) * 1);
}

.padding-left-medium {
  padding-left: calc(var(--spacing) * 1.5);
}

.padding-left-large {
  padding-left: calc(var(--spacing) * 2);
}

.padding-left-larger {
  padding-left: calc(var(--spacing) * 3);
}

/*global padding vertical spacing classes*/
.padding-vertical-smaller {
  padding: calc(var(--spacing) * 0.25) 0;
}

.padding-vertical-small {
  padding: calc(var(--spacing) * 0.5) 0;
}

.padding-vertical-default {
  padding: calc(var(--spacing) * 1) 0;
}

.padding-vertical-medium {
  padding: calc(var(--spacing) * 1.5) 0;
}

.padding-vertical-large {
  padding: calc(var(--spacing) * 2) 0;
}

.padding-vertical-larger {
  padding: calc(var(--spacing) * 3) 0;
}

/*global padding horizontal spacing classes*/
.padding-horizontal-smaller {
  padding: 0 calc(var(--spacing) * 0.25);
}

.padding-horizontal-small {
  padding: 0 calc(var(--spacing) * 0.5);
}

.padding-horizontal-default {
  padding: 0 calc(var(--spacing) * 1);
}

.padding-horizontal-medium {
  padding: 0 calc(var(--spacing) * 1.5);
}

.padding-horizontal-large {
  padding: 0 calc(var(--spacing) * 2);
}

.padding-horizontal-larger {
  padding: 0 calc(var(--spacing) * 3);
}

/* */

/* global margin classes*/
.margin-auto {
  margin: auto;
}

.margin-vertical-auto {
  margin: auto 0;
}

.margin-horizontal-auto {
  margin: 0 auto;
}

.margin-smaller {
  margin: calc(var(--spacing) * 0.25);
}

.margin-small {
  margin: calc(var(--spacing) * 0.5);
}

.margin-default {
  margin: calc(var(--spacing) * 1);
}

.margin-medium {
  margin: calc(var(--spacing) * 1.5);
}

.margin-large {
  margin: calc(var(--spacing) * 2);
}

.margin-larger {
  margin: calc(var(--spacing) * 3);
}

/*global margin top spacing classes*/
.margin-top-smaller {
  margin-top: calc(var(--spacing) * 0.25);
}

.margin-top-small {
  margin-top: calc(var(--spacing) * 0.5);
}

.margin-top-default {
  margin-top: calc(var(--spacing) * 1);
}

.margin-top-medium {
  margin-top: calc(var(--spacing) * 1.5);
}

.margin-top-large {
  margin-top: calc(var(--spacing) * 2);
}

.margin-top-larger {
  margin-top: calc(var(--spacing) * 3);
}

/*global margin bottom spacing classes*/
.margin-bottom-smaller {
  margin-bottom: calc(var(--spacing) * 0.25);
}

.margin-bottom-small {
  margin-bottom: calc(var(--spacing) * 0.5);
}

.margin-bottom-default {
  margin-bottom: calc(var(--spacing) * 1);
}

.margin-bottom-medium {
  margin-bottom: calc(var(--spacing) * 1.5);
}

.margin-bottom-large {
  margin-bottom: calc(var(--spacing) * 2);
}

.margin-bottom-larger {
  margin-bottom: calc(var(--spacing) * 3);
}

/*global margin right spacing classes*/
.margin-right-smaller {
  margin-right: calc(var(--spacing) * 0.25);
}

.margin-right-small {
  margin-right: calc(var(--spacing) * 0.5);
}

.margin-right-default {
  margin-right: calc(var(--spacing) * 1);
}

.margin-right-medium {
  margin-right: calc(var(--spacing) * 1.5);
}

.margin-right-large {
  margin-right: calc(var(--spacing) * 2);
}

.margin-right-larger {
  margin-right: calc(var(--spacing) * 3);
}

/*-- global margin left spacing classes --*/
.margin-left-smaller {
  margin-left: calc(var(--spacing) * 0.25);
}

.margin-left-small {
  margin-left: calc(var(--spacing) * 0.5);
}

.margin-left-default {
  margin-left: calc(var(--spacing) * 1);
}

.margin-left-medium {
  margin-left: calc(var(--spacing) * 1.5);
}

.margin-left-large {
  margin-left: calc(var(--spacing) * 2);
}

.margin-left-larger {
  margin-left: calc(var(--spacing) * 3);
}

/*global margin vertical spacing classes*/
.margin-vertical-smaller {
  margin: calc(var(--spacing) * 0.25) 0;
}

.margin-vertical-small {
  margin: calc(var(--spacing) * 0.5) 0;
}

.margin-vertical-default {
  margin: calc(var(--spacing) * 1) 0;
}

.margin-vertical-medium {
  margin: calc(var(--spacing) * 1.5) 0;
}

.margin-vertical-large {
  margin: calc(var(--spacing) * 2) 0;
}

.margin-vertical-larger {
  margin: calc(var(--spacing) * 3) 0;
}

/*global margin horizontal spacing classes*/
.margin-horizontal-smaller {
  margin: 0 calc(var(--spacing) * 0.25);
}

.margin-horizontal-small {
  margin: 0 calc(var(--spacing) * 0.5);
}

.margin-horizontal-default {
  margin: 0 calc(var(--spacing) * 1);
}

.margin-horizontal-medium {
  margin: 0 calc(var(--spacing) * 1.5);
}

.margin-horizontal-large {
  margin: 0 calc(var(--spacing) * 2);
}

.margin-horizontal-larger {
  margin: 0 calc(var(--spacing) * 3);
}

p {
  margin: 0;
}

a {
  color: #00a000 !important;
  text-decoration: none;
}

a:hover {
  color: #00a000 !important;
  text-decoration: underline;
}

button {
  border: none;
}

.border-none {
  border: none;
}

.white-space-nowrap {
  white-space: nowrap;
}

.text-overflow-ellipsis {
  text-overflow: ellipsis;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.text-align-left {
  text-align: left;
}

.text-underline {
  text-decoration: underline;
  text-underline-offset: 4px;
}

.text-transform-capitalize {
  text-transform: capitalize;
}

.text-transform-uppercase {
  text-transform: uppercase;
}

.text-transform-lowercase {
  text-transform: lowercase;
}

.input-error-border {
  border: 1px solid #e64539;
}

.input-text-clip {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

/* display positions global classes*/
.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.position-fixed {
  position: fixed;
}

.position-absolute {
  position: absolute;
}

.position-relative {
  position: relative;
}

.position-sticky {
  position: sticky;
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

.right-0 {
  right: 0;
}

.left-0 {
  left: 0;
}

.absolute-center-self-vertically {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.position-absolute-center-self {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.position-fixed-center-self {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.absolute-center-self-horizontally {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

/* ------------- display flex global classes ------- */
.display-flex {
  display: flex;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.display-grid {
  display: grid;
}

.opacity-zero {
  opacity: 0;
}

.width-10-percentage {
  width: 10%;
}

.width-20-percentage {
  width: 20%;
}

.width-30-percentage {
  width: 30%;
}

.width-40-percentage {
  width: 40%;
}

.width-50-percentage {
  width: 50%;
}

.width-60-percentage {
  width: 60%;
}

.width-70-percentage {
  width: 70%;
}

.width-80-percentage {
  width: 80%;
}

.width-90-percentage {
  width: 90%;
}

.inherit-parent-width {
  width: 100%;
}

.height-inherit {
  height: inherit;
}

.width-inherit {
  width: inherit;
}

.width-fit-content {
  width: fit-content;
}

.max-width-100-percentage {
  max-width: 100%;
}

.max-width-500px {
  max-width: 500px;
}

.max-width-588px {
  max-width: 588px;
}

.max-width-630px {
  max-width: 630px;
}

.max-width-767px {
  max-width: 767px;
}

.height-10-percentage {
  height: 10%;
}

.height-20-percentage {
  height: 20%;
}

.height-30-percentage {
  height: 30%;
}

.height-40-percentage {
  height: 40%;
}

.height-50-percentage {
  height: 50%;
}

.height-60-percentage {
  height: 60%;
}

.height-70-percentage {
  height: 70%;
}

.height-80-percentage {
  height: 80%;
}

.height-90-percentage {
  height: 90%;
}

.inherit-parent-width {
  width: 100%;
}

.inherit-parent-height {
  height: 100%;
}

.min-height-100-percentage {
  min-height: 100%;
}

.max-height-90-percentage {
  max-height: 90%;
}

.height-fit-content {
  height: fit-content;
}

.width-fit-content {
  width: fit-content;
}

.height-auto {
  height: auto;
}

.height-48px {
  min-height: 48px;
}

.flex-align-items-end {
  display: flex;
  align-items: flex-end;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-start {
  display: flex;
  align-items: flex-start;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-justify-content-space-between {
  display: flex;
  justify-content: space-between;
}

.flex-justify-content-space-evenly {
  display: flex;
  justify-content: space-evenly;
}

.flex-direction-column {
  flex-direction: column;
}

.flex-direction-row {
  display: flex;
  flex-direction: row;
}

.flex-place-children-page-center {
  display: flex;
  align-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.flex-start-place-children-vertically {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.flex-center-children-vertically {
  display: flex;
  align-items: center;
}

.flex-center-children-horizontally {
  display: flex;
  justify-content: center;
}

.flex-justify-content-center {
  display: flex;
  justify-content: center;
}

.flex-align-items-center {
  align-items: center;
}

.fixed-header-padding {
  padding-top: 80px;
}

/* Ripple effect */
.ripple-element {
  position: relative;
  transition: background 400ms;
  overflow: hidden;
  cursor: pointer;
}

.ripple-span.ripples {
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  height: inherit;
  overflow: hidden;
  animation: ripples 600ms linear;
  border-radius: 50%;
  background-color: rgba(143, 143, 143, 0.397);
}

@keyframes ripples {
  to {
    transform: scale(4);
    opacity: 0;
  }
}

/* shimmer effect */
.shimmer {
  animation-duration: 2.2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: rgb(238, 238, 238);
  background: linear-gradient(
    72deg,
    rgba(238, 238, 238, 0.9821564749385534) 8%,
    rgba(252, 252, 252, 0.9653025423542837) 18%,
    rgba(240, 238, 238, 0.9681115311183286) 33%
  );
  background-size: 1200px 100%;
  cursor: wait;
}

/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--primaryColor);
  color: var(--primaryColor);
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--primaryColor);
  color: var(--primaryColor);
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--primaryColor);
  color: var(--primaryColor);
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color: var(--primaryColor);
  }

  50%,
  100% {
    background-color: #ebe6ff;
  }
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-auto {
  overflow: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.overflow-x-auto {
  overflow-x: auto;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  scrollbar-color: transparent;
  scrollbar-width: 0;
}

.overflow-x-scroll {
  overflow-x: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.overflow-scroll::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.remaining-body-height {
  height: calc(100% - 64px);
}

.remaining-body-height_-128px {
  height: calc(100% - 128px);
}

.remaining-height-actions-header {
  height: calc(100% - 73px);
}

.object-fit-cover {
  object-fit: cover;
}

@-webkit-keyframes shimmer {
  0% {
    background-position: -100% 0;
  }

  100% {
    background-position: 100% 0;
  }
}

@keyframes shimmer {
  0% {
    background-position: -1200px 0;
  }

  100% {
    background-position: 1200px 0;
  }
}
