.height_and_width_24px {
  height: 24px;
  width: 24px;
}

.height_and_width_44px {
  height: 44px;
  width: 44px;
}

.line-height-14px {
  line-height: 14px;
}

.height_and_width_84px {
  height: 84px;
  width: 84px;
}

.height_and_width_14px {
  height: 14px;
  width: 14px;
}

.border-2px-transparent {
  border: 2px solid transparent;
}

.border-1px-grey {
  border: 1px solid;
  border-color: #C2C2C2;

  /* opacity: 0.6; */
}

.position-right-0 {
  right: 0;
}

.position-bottom-0 {
  bottom: 0;
}

.position-top-0 {
  top: 0;
}

.position-left-0 {
  left: 0;
}

.notification-count {
  padding: 0.9em;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: -0.3em;
  left: -0.3em;
}

.edit-profile-picture-icon-position {
  padding: 2px;
  bottom: 5px;
}

.edit-profile-picture-icon-size {
  height: 14px;
  width: 14px;
}
.left-minus-0-p-1-em {
  left: -0.1em;
}

.overflow-hidden {
  overflow: hidden;
}

.height-width-5em {
  height: 5em;
  width: 5em;
}

.float-left {
  float: left;
}

.height-0P8em {
  height: 0.8em;
}

.width-20percentage {
  width: 20%;
}

.width-30percentage {
  width: 30%;
}

.width-50percentage {
  width: 50%;
}

.width-80percentage {
  width: 80%;
}

.max-height-900px {
  max-height: 900px;
}
.height-90-percent {
  height: 90%;
}
.flex-justify-content-space-around {
  display: flex;
  justify-content: space-around;
}
.max-height-width-400px {
  max-width: 400px;
  max-height: 400px;
}
.width-150px {
  width: 150px;
}
.hover-effect-image:hover {
  z-index: -1;
  background-color: #4e4e4e;
}
.circle {
  border-radius: 50%;
}
.crop-modal-width {
  max-width: 400px;
}
.edit-image {
  position: relative;
}
.max-height-80vh {
  max-height: 80vh;
}
.max-width-100-percentage {
  max-width: 100%;
}

.rounded-profile-picture-small-height-width {
  height: 32px;
  width: 32px;
}

.rounded-profile-picture-display-letter-font-size {
  font-size: 42px;
  padding-top: 12px;
}

.rounded-profile-picture-display-letter-font-size-small {
  font-size: 18px;
  padding-top: 4px;
}

.rounded-profile-picture-display-letter-font-size-medium {
  font-size: 26px;
  padding-top: 4px;
}

.rounded-profile-picture-display-letter-font-size-smaller {
  font-size: 14px;
  padding-top: 2px;
}

.bottom-0-point-4-em {
  bottom: 0.4em;
}

.border-none {
  border: none;
}
/* onTab screen */
@media screen and (max-width: 4000px) {
  .crop-modal-width {
    max-width: 1000px;
  }
}

/* onTab screen */
@media screen and (max-width: 575px) {
  .crop-modal-width {
    max-width: 500px;
  }
}

@media screen and (max-width: 400px) {
  /*Max 5 Notification will be displayed */
  .crop-modal-width {
    max-width: 360px;
  }
}

/* very small Mobile screens */
@media screen and (max-width: 360px) {
  /*Max 5 Notification will be displayed */
  .crop-modal-width {
    max-width: 310px;
  }
}

@media screen and (min-width: 576px) {
  .adaptive-height_and_width_24px {
    height: 36px;
    width: 36px;
  }

  .adaptive-height_and_width_44px {
    height: 66px;
    width: 66px;
  }

  .adaptive-height_and_width_84px {
    height: 126px;
    width: 126px;
  }

  .adaptive-rounded-profile-picture-small-height-width {
    height: 48px;
    width: 48px;
  }

  .adaptive-edit-profile-picture-icon-position {
    padding: 2px;
    bottom: 5px;
  }

  .adaptive-edit-profile-picture-icon-size {
    height: 22px;
    width: 22px;
  }

  .adaptive-rounded-profile-picture-display-letter-font-size {
    font-size: 54px;
    padding-top: 28px;
  }

  .adaptive-rounded-profile-picture-display-letter-font-size-small {
    font-size: 26px;
    padding-top: 6px;
  }

  .adaptive-rounded-profile-picture-display-letter-font-size-medium {
    font-size: 40px;
    padding-top: 6px;
  }

  .adaptive-rounded-profile-picture-display-letter-font-size-smaller {
    font-size: 20px;
    padding-top: 4px;
  }
}
