.horizontal-right-border-gradient {
  background: linear-gradient(
    90deg,
    hsla(0, 0%, 100%, 1) 0%,
    hsla(0, 0%, 89%, 1) 52%
  );
}

.horizontal-left-border-gradient {
  background: linear-gradient(
    90deg,
    hsla(0, 0%, 89%, 1) 52%,
    hsla(0, 0%, 100%, 1) 100%
  );
}

.vertical-top-border-gradient {
  background: linear-gradient(
    180deg,
    hsla(0, 0%, 89%, 1) 52%,
    hsla(0, 0%, 100%, 1) 100%
  );
}

.vertical-bottom-border-gradient {
  background: linear-gradient(
    0deg,
    hsla(0, 0%, 89%, 1) 52%,
    hsla(0, 0%, 100%, 1) 100%
  );
}

.overlay {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0px;
  left: 18px;
  width: 80%;
  height: 94%;
  background: rgba(255, 255, 255, 0.14);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  z-index: 10;
}
