.face-loading-overlay {
  position: absolute;
  top: 2px;
  left: 2px;
  background: rgba(255, 255, 255, 0.14);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  z-index: 10;
}
